import React from 'react';
import { PortalWithState } from 'react-portal';

import Overlay from '../../components/Overlay';
import Icon from 'Shared/components/Icon';

import * as styles from './MarkenProdukte.module.css';
import Image from 'Shared/components/Image';

MarkenProdukte.propTypes = {};
MarkenProdukte.defaultProps = {};

function MarkenProdukte({ data }) {
  return (
    <section className={styles.Markenprodukte}>
      <PortalWithState
        closeOnOutsideClick
        closeOnEsc
        // eslint-disable-next-line no-undef
        node={typeof document !== 'undefined' && document.getElementById('portal')}
      >
        {({ openPortal, closePortal, isOpen, portal }) => (
          <React.Fragment>
            <a onClick={openPortal}>
              Wir verwenden ausschließlich Markenprodukte&nbsp;
              <Icon icon="arrow_right" />
            </a>
            {portal(
              <Overlay closePortal={closePortal}>
                <h3 className={styles.title}>Wir verwenden ausschliesslich Markenprodukte</h3>
                <div className={styles.brand}>
                  {data.edges.map(({ node }) => {
                    return (
                      <a className={styles.brand} target="_blank" href={node.homepage} key={node.name}>
                        <Image data={node.logo} alt={node.name} />
                      </a>
                    );
                  })}
                </div>
              </Overlay>,
            )}
          </React.Fragment>
        )}
      </PortalWithState>
    </section>
  );
}

export default MarkenProdukte;
