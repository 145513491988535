import * as React from 'react';

import DefaultTextBlock from '../blocks/DefaultTextBlock';
import HistoryTextBlock from '../blocks/HistoryTextBlock';
import CallToAction from '../blocks/CallToAction';
import HeaderImage from '../blocks/HeaderImage';
import ScrollToTopButton from '../components/ScrollToTopButton';
import FluidTextBlock from '../blocks/FluidTextBlock';
import Services from '../blocks/Services';
import MarkenProdukte from '../blocks/MarkenProdukte';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

import { DefaultLayout } from '../layouts/Default';
import { graphql, PageProps } from 'gatsby';

// @ts-ignore
import { highlightedSection } from './installateur.module.css';

interface DataProps {
  contactData: any;
  pageData: any;
  siteData: any;
  brands: any;
}

const InstallateurPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const { brands, contactData, pageData, siteData } = data;
  const {
    title: headerTitle,
    leistungenTitle,
    leistungen,
    header,
    geschichteText,
    unternehmenText,
    schauraumText,
    leistungenText,
    CTA,
    metadata,
    opengraph,
  } = pageData;
  const { title, canonicalSiteUrl } = siteData.siteMetadata;

  return (
    <DefaultLayout>
      <HeaderImage title={headerTitle} data={header} />
      <Services title={leistungenTitle} data={leistungen} />
      <div className="withBorders">
        <DefaultTextBlock data={leistungenText} id="leistungen" />
        <MarkenProdukte data={brands} />
        <CallToAction data={CTA} contactData={contactData} />
      </div>
      <div className={highlightedSection} id="schauraum">
        <DefaultTextBlock data={schauraumText} />
      </div>

      {unternehmenText.map((paragraph, index) => (
        <FluidTextBlock data={paragraph} key={paragraph.text} id={index === 0 ? 'unternehmen' : ''} />
      ))}

      <div className={highlightedSection}>
        <HistoryTextBlock data={geschichteText} id="geschichte" />
      </div>

      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={title}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
      <ScrollToTopButton />
    </DefaultLayout>
  );
};

export default InstallateurPage;

export const InstallateurPageQuery = graphql`
  query InstallateurPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    brands: allCockpitMarken(filter: { published: { eq: true } }) {
      edges {
        node {
          id
          name
          homepage
          logo {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 100, height: 75)
            }
          }
        }
      }
    }

    references: allCockpitProjekte(filter: { published: { eq: true } }) {
      edges {
        node {
          id
          name
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    contactData: cockpitRegionKontaktdaten {
      id
      postcode
      street
      city
      telephone
      fax
      email
      www
      gisCoordinates {
        lat
        lng
      }
      mapLink
      mapLinkZimmer
    }

    pageData: cockpitPageInstallateur {
      title
      header {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        link1 {
          label
          target
        }
        link2 {
          label
          target
        }
        link3 {
          label
          target
        }
      }

      CTA {
        title
        text
        linkLabel
      }

      schauraumText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      leistungenTitle
      leistungen {
        name
        icon
        image {
          childImageSharp {
            gatsbyImageData(width: 533, height: 300)
          }
        }
      }
      leistungenText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      projekteText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      unternehmenText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      geschichteText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
